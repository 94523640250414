@use '@typography' as *;
@use '@queries' as *;

.container__legal-info {
  background: var(--global-white);
  padding: 12px;
  border-radius: var(--cornerradius8);
  box-shadow: inset 0 0 0 1px var(--cst-legal-textbox-outline);
  z-index: 1;

  .legal-text {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: Inter, sans-serif;
    @include typography-s;
    @include weight-regular;
    color: var(--cst-legal-text);

    @include min-428-break {
      gap: 12px;
      @include typography-base;
    }
  }

  .text-wrapper {
    padding: 12px;
    border: 2px solid var(--cst-legal-textbox-outline);
    border-radius: 8px;

    @include min-428-break {
      padding: 16px;
      border-radius: 10px;
    }
  }
}
