@use '@typography' as *;
@use '@queries' as *;

.container__header {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  align-items: center;
  z-index: 1;

  .title {
    color: var(--cst-legal-title);
    @include typography-m;
    @include weight-semi-bold;

    @include min-428-break {
      @include typography-l;
    }
  }

  .subtitle {
    color: var(--cst-legal-subtitle);
    @include typography-s;
    @include weight-medium;

    @include min-428-break {
      @include typography-base;
    }
  }
}
